// IMPORTS
@import (reference) "../settings.less";

// PILLS
.pills-group {

    &:before {
        content: attr(data-pills-group-name) + ':';
        color: @header-color;
        font-size: 12px;
        font-weight: 600;
        display: block;
    }
}

.single-pill {
    background-color: @light-bg;
    display: inline-flex;
    margin: 4px 4px 0 0;
    align-items: center;
    font-size: 14px;
    padding: 3px 10px;
    cursor: pointer;
    border-radius: var(--borderRadius);

    &:after {
        content: '\e803';
        font-family: icomoon;
        font-size: 9px;
        margin-left: 8px;
    }

    &:nth-of-type(1) {
        clear: left;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        background-color: @border-color;
    }
}

.remove-all-filters {
    padding: 3px 0;
    cursor: pointer;
    color: @header-color;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;

    &:before {
        content: '\e803';
        font-family: icomoon;
        font-weight: normal;
        font-size: 9px;
        margin-right: 8px;
    }

    &:hover {
        color: @error-color;
    }
}

// SIDEBAR TABS
.sidebar-tabs {
    border-radius: var(--borderRadius);
    overflow: hidden;

    .products-list-tabs {

        > strong {
            display: block;
            color: white;
            background-color: @sec-color;
            font-size: 16px;
            font-weight: 600;
            padding: 14px 23px 13px;
        }
    }

    nav {
        background-color: #54728E;

        button {
            cursor: pointer;
            user-select: none;
            flex-grow: 1;
            color: white;
            text-align: center;
            line-height: 51px;
            font-size: 16px;
            font-weight: 600;

            &[aria-selected="true"] {
                background-color: #3D5B77;
            }
        }
    }

    .products-inner {
        border: 4px solid @border-color;
        border-top: 0;
    }
}

// SIDEBAR PRODUCTS
.sidebar-product {
    border-bottom: 1px solid @border-color;
    display: flex;
    padding: 0 19px;
    height: 112px;
    align-items: center;

    &:last-child {
        border-bottom: 0;
    }

    > div {

        &:nth-child(1) {
            width: 55px;
            margin-right: 16px;
            flex-shrink: 0;
        }

        &:nth-child(2) {
            flex-grow: 1;
        }
    }

    &:hover .sidebar-product-name {
        text-decoration: underline;
    }
}

.sidebar-product-image {
    width: 100%;
    height: 55px; 
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        width: auto;
        height: auto;
    }
}

.sidebar-product-badge {
    display: block;
    color: white;
    background-color: @error-color;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
}

a.sidebar-product-name {
    font-size: 14px;
    height: 41px;
    overflow: hidden;
    color: @header-color;
    font-weight: normal;
    display: block;
}

.sidebar-product-price {
    display: block;

    b,
    del {

        &:after {
            content: ' zł';
        }
    }

    b {
        font-weight: 600;
        color: @error-color;
    }

    del {
        color: #9A9A9A;
        margin-left: 6px;
    }
}

// PAGINATION
.pagination {
    display: flex;
    justify-content: center;
    gap: 50px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        background-color: white;
        border: 1px solid @border-color;
        border-radius: 3px;
        color: @header-color;
    }

    > a {

        &.pagination-prev:after {
            transform: rotate(90deg);
        }

        &.pagination-next:after {
            transform: rotate(-90deg);
        }

        &:after {
            content: '\e800';
            font-family: icomoon;
            font-size: 7px;
        }
    }

    ul {
        display: flex;
        gap: 4px;
    }

    li {
        display: none;

        @media (min-width: 601px) {
            display: block;
        }

        &:first-child,
        &.selected,
        &.prev-to-selected,
        &.next-to-selected,
        &:last-child {
            display: block;
        }

        &:nth-child(2):not(.next-to-selected):not(.selected):not(.prev-to-selected),
        &:nth-last-child(-n+2):not(:last-child):not(.next-to-selected):not(.selected):not(.prev-to-selected) {
            position: relative;
            display: block;

            &:before {
                content: '...';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-size: 22px;
            }
        }
    }

    li.selected > a,
    a:hover {
        color: white;
        background-color: @sec-color;
        border-color: @sec-color;
    }
}